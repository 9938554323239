<template>
    <div class="rounded-lg py-2 border rounded-lg mt-3 w-full lg:w-full">
        <div class="relative w-full">
            <div class="absolute right-2">
                <svg width="20" height="20" viewBox="0 0 28.164 23.242" class="cursor-pointer" @click="toogleFavourite" v-if="!favouriteSate">
                    <path
                        id="_23"
                        data-name="23"
                        d="M16.1,27.012a1.412,1.412,0,0,1-.833-.268L4.8,19.034a.734.734,0,0,1-.155-.141A8.833,8.833,0,0,1,16.1,5.507a8.839,8.839,0,0,1,11.522.861h0a8.853,8.853,0,0,1,0,12.525.735.735,0,0,1-.155.141L16.932,26.687A1.412,1.412,0,0,1,16.1,27.012ZM6.5,16.8l9.6,7.06,9.6-7.06a6.043,6.043,0,0,0-.071-8.472h0a6.029,6.029,0,0,0-8.472,0,1.412,1.412,0,0,1-2.005,0A6.056,6.056,0,0,0,6.5,16.8Z"
                        transform="translate(-2.052 -3.77)"
                        fill="#231f20"
                        opacity="0.5"
                    />
                    <path id="Path_50" data-name="Path 50" d="M2605.027,1375.483l3.363-2.4,5.89.491,4.247,2.575v4.546l-.806,4.555-4.806,4.015-7.888,2.158-4.967-3.347-4.8-6.758-.3-4.122,1.56-2.432,4.8-1.19Z" transform="translate(-2593 -1371.54)" fill="#231f20" opacity="0" />
                </svg>
                <svg width="20" height="20" viewBox="0 0 28.164 23.242" @click="toogleFavourite" v-if="favouriteSate">
                    <path
                        id="_23"
                        data-name="23"
                        d="M16.1,27.012a1.412,1.412,0,0,1-.833-.268L4.8,19.034a.734.734,0,0,1-.155-.141A8.833,8.833,0,0,1,16.1,5.507a8.839,8.839,0,0,1,11.522.861h0a8.853,8.853,0,0,1,0,12.525.735.735,0,0,1-.155.141L16.932,26.687A1.412,1.412,0,0,1,16.1,27.012ZM6.5,16.8l9.6,7.06,9.6-7.06a6.043,6.043,0,0,0-.071-8.472h0a6.029,6.029,0,0,0-8.472,0,1.412,1.412,0,0,1-2.005,0A6.056,6.056,0,0,0,6.5,16.8Z"
                        transform="translate(-2.052 -3.77)"
                        fill="#eb3b5a"
                    />
                    <path id="Path_50" data-name="Path 50" d="M2605.422,1373.578l3.578,1.19,5.655-1.681,5.6,6.636a23.222,23.222,0,0,1-3.26,5.942,31.3,31.3,0,0,1-4.188,3.753l-5.915,4.255-5.9-5.117-6.04-5.024V1377.2l1.56-2.432,4.8-1.19Z" transform="translate(-2593 -1371.54)" fill="#eb3b5a" />
                </svg>
            </div>
        </div>
        <div class="flex items-center flex-wrap mt-2 lg:mt-0">
            <div class="w-2/5 md:w-4/12 lg:w-3/12 flex justify-center lg:px-5">
                <Link :href="`/urun/${product.default_url.slug}`" class="">
                    <picture class="flex justify-center">
                        <source :srcset="product.imagesOptimized[0]?.urlThumbWebP" type="image/webp" />
                        <source :srcset="product.imagesOptimized[0]?.urlThumbOrj" type="image/jpeg" />
                        <img :src="product.imagesOptimized[0]?.urlThumbOrj" alt="Alt Text!" />
                    </picture>
                </Link>
            </div>
            <div class="block md:hidden w-3/5">
                <div class="text-base font-semibold mt-3 lg:mt-0">{{ product.attribute_data.name.tr }}</div>
                <div>
                    <a :href="`/urun/${product.default_url.slug}`" class="text-sm text-brand-color font-semibold">{{ product.brand.name }}</a>
                </div>
                <div class="text-xs mt-1 text-kbgray">
                    <text-clamp :text="product.attribute_data.excerpt?.tr" :max-lines="2" />
                </div>
            </div>
            <div class="w-full md:w-8/12 lg:w-9/12">
                <div class="hidden md:block text-lg font-semibold mt-3 lg:mt-0">
                    <a :href="`/urun/${product.default_url.slug}`"> {{ product.attribute_data.name.tr }}</a>
                </div>
                <div class="hidden md:block mt-3 lg:mt-0">
                    <a :href="`/urun/${product.default_url.slug}`" class="text-sm text-brand-color font-semibold">{{ product.brand.name }}</a>
                </div>
                <div class="hidden md:block text-xs mt-3 text-kbgray">
                    <text-clamp :text="product.attribute_data.excerpt?.tr" :max-lines="2" />
                </div>
                <div class="flex flex-col md:flex-row mt-9 lg:mt-3">
                    <div class="max-w-[300px] lg:max-w-[350px] w-full">
                        <ul class="flex border rounded-full bg-white w-full items-center justify-center shadow-xl h-10 ts:h-12">
                            <template v-for="item in subscribetionMonthsOrdered">
                                <li class="text-textgray whitespace-nowrap w-16 text-xs text-center hover:scale-125 transform duration-300 ease-in-out" :class="[selectedMonth == item[1].subscription_months.id ? selectedClass : '']" @click="selectMonth(item[1].subscription_months.id)" v-if="item[1].price">
                                    {{ item[1].subscription_months?.name }}
                                </li>
                            </template>
                        </ul>
                        <!--                    <div class="flex text-xs text-center cursor-pointer">-->
                        <!--                        <div class="border-y-2 w-1/5 p-2 border-l-2" :class="[selectedMonth == 1 ? selectedClass : 'rounded-l-xl']" @click="selectMonth(1)">1 Ay</div>-->
                        <!--                        <div class="border-y-2 w-1/5 p-2" :class="[selectedMonth == 3 ? selectedClass : '']" @click="selectMonth(3)">3 Ay</div>-->
                        <!--                        <div class="border-y-2 w-1/5 p-2" :class="[selectedMonth == 6 ? selectedClass : '']" @click="selectMonth(6)">6 Ay</div>-->
                        <!--                        <div class="border-y-2 w-1/5 p-2" :class="[selectedMonth == 9 ? selectedClass : '']" @click="selectMonth(9)">9 Ay</div>-->
                        <!--                        <div class="border-y-2 border-r-2 w-1/5 p-2 rounded-r-xl">12 Ay</div>-->
                        <!--                    </div>-->
                    </div>
                    <div class="hidden ts:flex justify-center w-full ts:max-w-[calc(100%-350px)]">
                        <Link class="bg-black text-white rounded-full py-2 px-4 self-center font-semibold text-lg text-center whitespace-nowrap" :href="`/urun/${product.default_url.slug}`">Ürüne Git</Link>
                    </div>
                </div>
                <div class="flex mt-5 lg:mt-2 ml-2 md:ml-0">
                    <span class="font-semibold text-xl"
                        ><Link :href="`/urun/${product.default_url.slug}`"
                            ><span class="text-sm ts:text-base leading-none mb-1 mr-2 text-[#FE1151] line-through whitespace-nowrap" v-if="comparePrice > 0">{{ comparePrice }} TL </span> {{ price }} TL /</Link
                        ></span
                    >
                    <span class="text-xs self-center ml-2"><Link :href="`/urun/${product.default_url.slug}`"> Ay fiyatından kirala. </Link></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import TextClamp from "vue3-text-clamp";

export default {
    data() {
        return {
            favouriteSate: false,
            selectedMonth: null,
            //selectedClass: "rounded-full -m-2 py-4",
            selectedClass: " shadow-inner shadow-sm border-2 h-375 md:h-375x ts:h-16 rounded-full border-kbgreen p-2 lg:p-4 items-center justify-center flex bg-white !text-black text-xs hover:!scale-100",
            price: 0,
            comparePrice: 0,
            subscribetionMonthsOrdered: [],
        };
    },
    components: {
        Link,
        TextClamp,
    },
    props: {
        auth: Object,
        product: Object,
    },
    methods: {
        selectMonth(month) {
            this.selectedMonth = month;
            this.price = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months.id == month).price / 100;
            this.comparePrice = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months.id == month).compare_price / 100;
            if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
            if (this.comparePrice == 0) this.discountRate = null;
        },
        toogleFavourite() {
            // Kullanıcı login değil ise favori eklemek için login olmalı, yönlendir
            if (this.auth.token == null) {
                this.$inertia.get("/giris-yap");
            }

            window.axios.defaults.headers.common["Authorization"] = `Bearer ${this.auth.token}`;
            axios
                .post(`/favourite/${this.product.id}/toogle`, {
                    productSlug: this.product.default_url.slug,
                })
                .then((res) => {
                    if (res.data.status == "created") {
                        this.favouriteSate = true;
                    } else {
                        this.favouriteSate = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        if (this.product?.variants[0]?.prices.length > 0) this.subscribetionMonthsOrdered = Object.entries(this.product?.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
        this.selectedVariandId = this.product.variants[0].id;
        let nonZeroPrices = this.product.variants[0].prices.filter((price) => price.price > 0);
        this.subscribetionMonthsOrdered = this.subscribetionMonthsOrdered.filter((price) => price[1].price > 0);
        this.price = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price / 100;
        this.comparePrice = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.compare_price / 100;
        if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
        if (this.comparePrice == 0) this.discountRate = null;

        this.selectedMonth = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.subscription_months?.id;
        this.favouriteSate = this.product.favourite;
    },
};
</script>

<style scoped></style>
